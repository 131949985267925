<template>
  <Listbox as="div" class="store-switcher" :class="[isAddressLoaded ? higlightClass : '', size]" @click="disableHighlight">
    <div class="ss-box">
      <p class="ss-text" v-if="!hideTitle">
        Shipping Address
      </p>
      <!-- <span class="xs-text" v-if="selected?.nickname && isLoggedIn">{{
        selected.nickname
      }}</span> -->
      <ListboxButton class="group ss-btn">
        <span class="flex w-full justify-between items-center">
          <span class="flex min-w-0 items-center justify-between space-x-3">
            <div
              class="flex items-center justify-center rounded-full text-brand-indigodark bg-brand-indigo flex-shrink-0 order-bg "
              :class="size == 'small' ? 'w-6 h-6' : 'w-10 h-10'"
            >
              <Icon name="shop" :class="size == 'small' ? 'h-4 w-4' : 'w-6 h-6'" />
            </div>
            <span class="flex-1 flex flex-col min-w-0" v-if="activeShipping && Object.keys(activeShipping)?.length && isLoggedIn">
              <template v-if="activeShipping.nickname">
                <span class="text-brand-secondary text-sm font-medium truncate">{{ activeShipping.nickname }}</span>
                <span class=" text-brand-body text-xs truncate" v-if="activeShipping.address1">{{ activeShipping.address1 }}</span>
              </template>
              <template v-else>
                <span class="text-brand-secondary text-sm font-medium truncate">{{ activeShipping ? activeShipping.address1 : null }}</span>
                <span class="text-brand-secondary text-sm font-medium truncate">{{ activeShipping ? activeShipping.address2 : null }}</span>
              </template>
            </span>
            <span class="flex-1 flex flex-col min-w-0 leading-none space-y-1.5" v-else-if="!isAddressLoaded">
              <div class="h-3 font-medium rounded bg-gray-200 animate-pulse text-opacity-0 w-36">
                <span class="opacity-0">XXXX XXXX</span>
              </div>
              <div class="h-2.5 font-medium rounded bg-gray-200 animate-pulse text-opacity-0 w-24">
                <span class="opacity-0">XXXX XXXX</span>
              </div>
            </span>
          </span>
          <SelectorIcon class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        </span>
      </ListboxButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <ListboxOptions class="item-opts">
        <ListboxOption
          as="div"
          v-for="opt in allAddresses"
          :key="opt.id"
          :value="opt"
          @click="updateShippingAddress(opt)"
          class="group item-opt"
          :class="(activeShipping ? opt.trade_gecko_id == activeShipping.trade_gecko_id : false) ? 'bg-blue-100' : ''"
        >
          <span class="flex min-w-0 items-center justify-between space-x-3">
            <span class="flex-1 flex flex-col min-w-0">
              <span class="name">{{ opt.address1 }}</span>
              <span class="name">{{ opt.address2 }}</span>
              <span class="place">{{ opt.city }} , {{ opt.state }}</span>
            </span>
          </span>
        </ListboxOption>
      </ListboxOptions>
    </transition>
  </Listbox>

  <!-- Removable Notice -->
  <NoticeModal
    :is-visible="isItemsNoticeVisible"
    :data="removeableData"
    @close="isItemsNoticeVisible = !isItemsNoticeVisible"
    @confirm="removeItemsFn"
  />
</template>

<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { SelectorIcon } from "@heroicons/vue/solid";
import AccountSummary from "@/modules/AccountSummary";
import { useStore } from "vuex";
import { inject, computed, onMounted, ref, watch } from "vue";
import NoticeModal from "./NoticeModal";
import { reactive } from "vue";

export default {
  name: "AccountSwitch",
  emits: ["onSelect", "onNoActiveAddress"],
  props: {
    isLoggedIn: Boolean,
    hideTitle: Boolean,
    size: String,
    ignoreDefault: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    SelectorIcon,
    NoticeModal,
  },
  setup(props, { emit }) {
    const { address, getAddresses, selectaddress, selectAddress, checkLicenseBeforeChange } = AccountSummary();
    getAddresses();

    const isItemsNoticeVisible = ref(false);
    const removeableData = reactive({
      items: [],
      address: {},
    });

    const store = useStore();
    const axios = inject("axios");

    const higlightClass = ref("higlight");

    const allAddresses = computed(() => address?.data || []);

    const activeShipping = ref({});

    const currentCart = computed(() => store.getters.cart);

    const currentShipping = computed(() => store.getters.getSelectedAddress);

    const isAddressLoaded = computed(() => !address?.loading);

    const disableHighlight = () => {
      higlightClass.value = "";
      localStorage.setItem("ss_highlight", "clicked");
    };

    onMounted(() => {
      const isHighlightClicked = localStorage.getItem("ss_highlight");
      if (isHighlightClicked && isHighlightClicked === "clicked") {
        higlightClass.value = "";
      }
      if (props.ignoreDefault) {
        activeShipping.value = {
          nickname: "No Address Selected",
          address1: "Please select address",
        };
      }
      if (props.isMobile) {
        activeShipping.value = currentShipping.value;
      }
    });

    const getCurrentShippingAddress = () => {
      const placeholder = {
        nickname: "No Address Selected",
        address1: "Please select address",
      };

      // Don't check for address if on popup
      if (props.ignoreDefault) {
        activeShipping.value = placeholder;
        return;
      }

      if (isAddressLoaded.value) {
        if (!currentCart.value?.shipping_id) {
          activeShipping.value = placeholder;
          emit("onNoActiveAddress");
        } else {
          let updatingAddr = {};
          updatingAddr = allAddresses.value.find((addr) => addr.trade_gecko_id == currentCart.value?.shipping_id) || { ...placeholder };
          if (!updatingAddr?.trade_gecko_id) {
            activeShipping.value = { ...placeholder };
            emit("onNoActiveAddress");
          } else {
            activeShipping.value = { ...updatingAddr };
            store.dispatch("setSelectedAddress", { ...activeShipping.value });
          }
        }
      }
    };

    const removeItemsFn = async () => {
      let item_ids = removeableData.items.map((item) => item.id);
      await selectAddress(removeableData.address?.trade_gecko_id, item_ids);
      activeShipping.value = { ...removeableData.address };
      emit("onSelect");
      store.dispatch("setSelectedAddress", { ...activeShipping.value });
      store.dispatch("setAddressChange", true);
      isItemsNoticeVisible.value = false;

      // Refresh cart
      store.dispatch("getCart", { ax: axios });
    };

    const updateShippingAddress = async (addr) => {
      try {
        const res = await checkLicenseBeforeChange(addr.trade_gecko_id);
        if (res.msg == "Address Updated") {
          activeShipping.value = { ...addr };
          emit("onSelect");
          store.dispatch("setSelectedAddress", { ...activeShipping.value });
          store.dispatch("setAddressChange", true);
        } else {
          removeableData.items = res.data;
          removeableData.address = addr;
          isItemsNoticeVisible.value = true;
        }
      } catch (error) {
        console.error("Error fetching address:", error.message);
      }
    };

    // watch(isAddressLoaded, () => getCurrentShippingAddress());
    // watch(currentCart, () => getCurrentShippingAddress());

    watch(currentCart, () => {
      watch(isAddressLoaded, () => getCurrentShippingAddress());
      getCurrentShippingAddress();
    });

    watch(currentShipping, () => (activeShipping.value = { ...currentShipping.value }));

    return {
      allAddresses,
      selectaddress,
      selectAddress,
      higlightClass,
      isAddressLoaded,
      disableHighlight,
      activeShipping,
      updateShippingAddress,
      isItemsNoticeVisible,
      removeableData,
      removeItemsFn,
    };
  },
};
</script>

<style lang="scss">
.store-switcher {
  @apply relative w-full inline-block text-left;

  .ss-box {
    @apply relative;

    &:before {
      content: "";
      @apply absolute inset-0 pointer-events-none;
    }
  }

  &.higlight {
    .ss-box {
      &:before {
        @apply bg-theme-indigo opacity-20  animate-ping;
      }
    }
  }

  .ss-btn {
    @apply w-full px-6 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-transparent;
  }

  &.small {
    .ss-btn {
      @apply text-xs px-2 py-1;
    }
    .item-opts {
      min-width: 280px;
      width: 280px;
      @apply right-0;
      left: unset;
    }
  }

  .ss-text {
    @apply w-full px-6 py-2 text-sm text-left font-medium text-brand-gray;
  }

  .xs-text {
    @apply inline-block w-full px-6 pb-1 text-xs text-left font-normal text-brand-body;

    &:empty {
      @apply hidden;
    }
  }

  .item-opts {
    @apply z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none overflow-y-auto;
    max-height: 50vh;
    @include CssScrollbar();

    .item-opt {
      @apply w-full px-4 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-transparent cursor-pointer;
    }

    .name {
      @apply text-brand-secondary text-sm font-medium truncate;
    }

    .place {
      @apply text-brand-body text-xs truncate;
    }
  }
}
</style>
